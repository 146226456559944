div.c-following-actions-wrapper {
  --space: var(--spacing-01);

  flex-wrap: nowrap;
  background: var(--color-bg-alt);
  padding: var(--spacing-01);
  border: var(--border);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.c-following-actions {
  width: 100%;
}

.c-following-actions .c-following-filter {
  width: 100%;
  flex: 1;
}

header.c-following-actions-header {
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.c-following-actions-title {
  margin-right: auto;
  text-align: left;
}
