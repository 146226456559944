.header-site {
  width: 100%;
  padding-top: var(--spacing-02);
  color: var(--color-fg-alt);
}

.header-site .title-wrapper {
  --space: var(--spacing-h);

  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  display: flex;
  padding: var(--spacing-01) var(--spacing-02);
  padding-left: var(--spacing-01);
  border-right: var(--border);
}

.header-site .title {
  font-size: 1.6em;
  transform: translateY(-2px);
}

.header-site .logo {
  width: auto;
  display: block;
  height: 30px;
}

.header-site nav {
  gap: 0;
  justify-content: space-between;
  background-color: var(--color-bg-alt);
  overflow: hidden;
  flex-flow: row nowrap;
}

.header-site a {
  text-decoration: none;
  transition: background-color 0.1s ease;
}

.header-site a:hover {
  color: var(--color-input-fg);
  background-color: var(--color-hl);
}

.header-site .routes-wrapper {
  --space: 0;

  flex-flow: row nowrap;
}

.header-site .routes-wrapper a {
  border-left: var(--border);
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-01) var(--spacing-02);
}

@media (max-width: 36em) {
  .header-site .title-wrapper {
    border-right-color: transparent;
    padding: var(--spacing-01);
  }
}
