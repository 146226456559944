.c-radio-group-item-wrapper {
  --space: var(--spacing-h);

  align-items: center;
  justify-content: flex-start;
}

.c-radio-group-item {
  width: var(--size-500);
  height: var(--size-500);
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: var(--color-bg);
}

.c-radio-group-item:hover {
  background-color: var(--color-hl);
}

.c-radio-group-item[data-state="checked"] {
  background-color: var(--color-bg);
}

.c-radio-group-item-indicator {
  width: 75%;
  height: 75%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.c-radio-group-item-indicator::after {
  content: " ";
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 4px;
  background-color: var(--color-fg-alt);
}

.c-radio-group-item-label {
  cursor: pointer;
}
