.r-public {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.r-public-inner {
  max-width: 60ch;
  align-items: center;
  justify-content: center;
  background-color: var(--color-bg-alt);
}

.r-public .logo {
  margin: auto;
  width: clamp(100px, 50vw, 250px);
  display: block;
  height: auto;
}

.r-public-inner > div {
  padding: var(--spacing-02);
}

.r-public-inner > footer {
  width: 100%;
  border-top: var(--border);
  padding: var(--spacing-02);
  text-align: center;
}
