/* .c-list-row { } */

.c-list-row[data-state="add"] {
  background-color: var(--color-bg);
}

.c-list-row[data-state="remove"] {
  background-color: var(--color-danger);
}

header.c-list-row-header {
  gap: 0;
  position: relative;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
}

.c-list-row-header[data-is-over="true"] {
  font-weight: bold;
}

.c-list-row-action {
  text-transform: uppercase;
  padding: var(--spacing-02);
  flex: 1 1 50%;
  display: flex;
  flex-flow: row;
  align-items: center;
  transition: background-color 0.1s ease;
}

[data-open="true"] .c-list-row-action {
  border-bottom: var(--border);
}

.c-list-row-action[data-action="add"] {
  justify-content: flex-start;
}

.c-list-row-action[data-action="remove"] {
  justify-content: flex-end;
}

.c-list-row-action[data-action="add"][data-is-over="true"] {
  background-color: var(--color-hl);
}

.c-list-row-action[data-action="remove"][data-is-over="true"] {
  background-color: var(--color-danger);
}

.c-list-row-label {
  display: block;
  padding: var(--spacing-02) var(--spacing-01);
  overflow: hidden;
  text-overflow: ellipsis ellipsis;
  white-space: nowrap;
  flex: 1;
  text-align: center;
}

.c-list-row-header[data-is-over="true"] .c-list-row-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.c-list-row[data-open="true"] .c-list-row-label {
  width: 100%;
  border-bottom: var(--border);
}

.c-list-row form {
  background-color: var(--color-bg);
}

.c-list-row-edit-wrapper {
  --space: var(--spacing-01);

  background-color: var(--color-bg);
}
