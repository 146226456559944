.c-list-row-disclosure-button {
  padding: var(--spacing-02);
  border-radius: 0;
  border: none;
  flex: 0 0 auto;
  background: transparent;
}

.c-list-row-disclosure-button:first-of-type {
  border-right: var(--border);
}

.c-list-row-disclosure-button:last-of-type {
  border-left: var(--border);
}

.c-list-row-disclosure-button:hover {
  background: var(--color-bg);
}

.c-list-row-disclosure-button[data-action="delete"]:hover {
  background-color: var(--color-danger);
}

.c-list-row[data-open="true"][data-action="edit"]
  .c-list-row-disclosure-button[data-action="edit"] {
  background-color: var(--color-bg);
}

.c-list-row[data-open="true"][data-action="edit"]
  .c-list-row-disclosure-button[data-action="delete"] {
  border-bottom: var(--border);
}

.c-list-row[data-open="true"][data-action="delete"]
  .c-list-row-disclosure-button[data-action="delete"] {
  background-color: var(--color-danger);
}

.c-list-row[data-open="true"][data-action="delete"]
  .c-list-row-disclosure-button[data-action="edit"] {
  border-bottom: var(--border);
}
