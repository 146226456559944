button.c-following-row {
  width: 100%;
  padding: 0;
  gap: 0;
  text-align: left;
  color: inherit;
  background: var(--color-bg-alt);
  cursor: pointer;
  margin-top: calc(-1 * var(--border-width));
  position: relative;
  flex-flow: row nowrap;
  outline: none;
  border: var(--border);
  border-radius: 0;
  transition: transform 0.1s ease;
}

.c-following-row::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-size: auto;
  image-rendering: pixelated;
  mix-blend-mode: color-dodge;
}

.c-following-row[data-dragging="true"] {
  opacity: 0.8;
}

.c-following-row[data-selected="true"]::before {
  background-image: url("styles/pattern.png");
  font-weight: 600;
  font-variation-settings: "wght" 600;
}

.c-following-row:hover {
  background-color: var(--color-bg);
}

.c-following-row[data-selected="true"]:hover::before {
  background-image: url("styles/pattern-alt.png");
  mix-blend-mode: multiply;
}

.c-following-row[data-is-current-cursor="true"],
.c-following-row:focus {
  font-style: italic;
  font-variation-settings: "slnt" -10;
  border-right: var(--border);
  transform: translateX(var(--spacing-01));
}

div.c-following-row-content {
  --space: var(--spacing-h);

  flex: 1;
  z-index: 1;
  overflow: hidden;
  flex-flow: row nowrap;
  padding: var(--spacing-h) var(--spacing-h);
}

.c-following-row-avatar-wrapper {
  flex: 0 0 auto;
  width: 50px;
  height: auto;
  aspect-ratio: 1/1;
  overflow: hidden;
  margin: auto 0;
  border-radius: var(--border-radius);
}

.c-following-row-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-following-row-tag-list {
  list-style: none;
  padding: 0;
}

.c-following-row-info {
  --space: var(--spacing-q);

  overflow: hidden;
  text-overflow: ellipsis;
}

span.c-following-row-title {
  --space: var(--spacing-01);

  flex-flow: row nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
}

span.c-following-row-title > span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.c-following-row-subtitle {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.c-following-row-link {
  font-weight: bold;
  border: var(--border);
  background: var(--color-bg);
  border-radius: var(--border-radius);
  padding: 0 var(--spacing-q);
  text-decoration: none;
}

.c-following-row-link:hover {
  background: var(--color-bg-alt);
}

.c-following-row-drag {
  z-index: 1;
  border-left: var(--border);
  padding: var(--spacing-02);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  position: relative;
  align-self: stretch;
  flex: 0;
}

.c-following-row-drag .icon {
  z-index: 2;
}

.c-following-row-drag::before {
  /* border-left-width: 8px; */
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  transition: all 0.1s ease;
}

.c-following-row[data-selected="true"] .c-following-row-drag::before {
  background-color: var(--color-hl);
}

.c-following-row[data-selected="true"] .c-following-row-drag {
  /* color: var(--color-bg-alt); */
}
