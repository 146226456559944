:root {
  /* fonts */
  --font-sans: "Inter", sans-serif;
  --font-serif: "Piazzolla", serif;
  --font-redacted: "Redacted", sans-serif;
  --font-weight-light: 100;
  --font-weight-normal: 400;
  --font-weight-bold: 600;

  /* text */
  --text-paragraph: normal var(--font-weight-normal) var(--size-400) / 1.65
    var(--font-sans);
  --text-page-title: italic var(--font-weight-normal) var(--size-800) / 1.25
    var(--font-serif);
  --text-heading-2: italic var(--font-weight-bold) var(--size-600) / 1.25
    var(--font-serif);
  --text-heading-3: normal var(--font-weight-bold) var(--size-500) / 1.25
    var(--font-sans);
}

@supports (font-variation-settings: normal) {
  :root {
    /* fonts */

    /* --font-sans: "Inter var", sans-serif; */
  }
}
