div.r-instances-add {
  overflow: hidden;
  background: var(--color-bg-alt);
  margin-right: auto;
  gap: 0;
}

.r-instances-add > *:not(:first-child) {
  border-top: var(--border);
}

.r-instances-add-title {
  padding: var(--spacing-01) var(--spacing-02);
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.r-instances-add form {
  padding: var(--spacing-02);
}

.r-instances-add footer {
  padding: var(--spacing-01) var(--spacing-02);
  font-size: var(--size-400);
  text-align: center;
  font-style: italic;
  font-variation-settings: "slnt" -10;
  font-variant-numeric: tabular-nums;
}

.r-instances-add .input-wrapper {
  --space: var(--spacing-h);
}

.r-instances-add .c-message div {
  max-width: 30ch;
  margin: auto;
}

.r-instances-add .c-message p {
  text-align: left;
}

.r-instances-add button > span {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  min-width: 4ch;
  margin: auto;
}
