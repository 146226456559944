.c-following-drag-layer {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.c-following-drag-layer[data-is-dragging="false"] {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.c-following-drag-layer-badge-container {
  /* transition: transform 0.1s ease; */
}

[data-is-dragging="false"] .c-following-drag-layer-badge-container {
  padding: var(--spacing-02);
}

.c-following-drag-layer-badge {
  border: var(--border);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  font-variant-numeric: tabular-nums;
  font-weight: bold;
  font-style: italic;
  font-variation-settings: "slnt" -10;

  /* top: 50%; */

  /* left: 50%; */
  transition: transform 0.1s ease;
}

[data-is-empty="true"] .c-following-drag-layer-badge {
  transform: scale(0);
  color: transparent;
}

[data-is-dragging="false"] .c-following-drag-layer-badge {
  background-color: var(--color-hl);
}

[data-is-dragging="true"] .c-following-drag-layer-badge {
  background-color: var(--color-bg);
}
