@font-face {
  font-family: Piazzolla;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Piazzolla-Bold.woff2") format("woff2");
}

@font-face {
  font-family: Piazzolla;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("Piazzolla-BoldItalic.woff2") format("woff2");
}
