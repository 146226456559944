.c-tag {
  white-space: nowrap;
  line-height: 1em;
  border-radius: var(--border-radius);
  font-size: var(--size-300);
  font-weight: bold;
  display: inline;
  padding: var(--spacing-q) var(--spacing-01);
  color: var(--color-bg);
  background-color: var(--color-fg-alt);
  font-variant-numeric: tabular-nums;
}
