:root {
  accent-color: var(--color-hl);
}

body {
  font-family: var(--font-sans);
  color: var(--color-fg);
  background-color: var(--color-bg);
  line-height: var(--size-500);
  display: flex;
  flex-flow: column;
}

#root {
  display: flex;
  flex-flow: column;
  min-height: 100%;
}

main {
  flex: 1;
  padding-bottom: var(--spacing-10);
}

h1 {
  color: var(--color-fg-alt);
  font: var(--text-page-title);
}

h2 {
  color: var(--color-fg-alt);
  font: var(--text-heading-2);
}

h3 {
  color: var(--color-fg-alt);
  font: var(--text-heading-3);
}

h4 {
  color: var(--color-fg-alt);
  font-size: var(--size-400);
}

p {
  font: var(--text-paragraph);
}

strong {
  font-weight: var(--font-weight-bold);
}

time {
  font-variant-numeric: tabular-nums;
}

form {
  background: var(--color-bg-alt);
  padding: var(--spacing-01);
  align-items: flex-end;
}

button {
  color: inherit;
  border: var(--border);
  border-radius: var(--border-radius);
  background: var(--color-bg);
  padding: var(--spacing-q) var(--spacing-01);
  width: max-content;
  cursor: pointer;
  transition: background 0.1s ease;
}

button:hover {
  background: var(--color-bg-alt);
}

button:disabled {
  background-color: var(--color-input-bg);
  color: var(--color-input-fg);
  border-color: transparent;
  cursor: not-allowed;
}

input {
  border: var(--border);
  border-radius: var(--border-radius);
  color: var(--color-input-fg);
  background: var(--color-input-bg);
  padding: var(--spacing-q) var(--spacing-01);
}

input:disabled {
  background-color: var(--color-input-bg);
  color: var(--color-fg-alt);
  border-color: transparent;
  cursor: not-allowed;
}

.icon {
  font-size: var(--size-500);
  min-width: 30px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}
