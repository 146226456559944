:root {
  --color-01: #f5ffbb;
  --color-02: #70eba7;
  --color-03: #1f5226;
  --color-04: #5fc88f;
  --color-bg: var(--color-01);
  --color-bg-alt: var(--color-02);
  --color-fg: #212121;
  --color-fg-alt: var(--color-03);
  --color-border: var(--color-03);

  /* #b888ff; */
  --color-hl: #b888ff;
  --color-danger: #ffab00;
  --color-input-fg: #000;
  --color-input-bg: #fff;
}

[data-theme="merveilles"] {
  --color-bg: #000;
  --color-bg-alt: #222;
  --color-fg: #fff;
  --color-fg-alt: #fff;
  --color-border: #333;
  --color-hl: #72dec2;
  --color-hl-alt: #9ffce3;
  --color-danger: #ff8000;
  --color-input-fg: #000;
  --color-input-bg: #fff;
}

[data-theme="light"] {
  --color-bg: #eee;
  --color-bg-alt: #fff;
  --color-fg: #000;
  --color-fg-alt: #000;
  --color-border: #222;
  --color-hl: #b888ff;
  --color-danger: #ffab00;
  --color-input-fg: #000;
  --color-input-bg: #fff;
}

[data-theme="forum"] {
  --color-bg: #fdd588;
  --color-bg-alt: wheat;
  --color-fg: #000;
  --color-fg-alt: darkred;
  --color-border: #222;
  --color-hl: #f77676;
  --color-danger: #ffab00;
  --color-input-fg: #000;
  --color-input-bg: #fff;
}
