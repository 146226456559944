ul.c-list-list {
  --space: 0;

  list-style: none;
  padding: 0;
}

ul.c-list-list .c-list-row:not(:last-child) {
  border-bottom: var(--border);
}

.c-list-lists-loading {
  width: 100%;
  padding: var(--spacing-02);
  display: flex;
  align-items: center;
  justify-content: center;
}
