div.c-following-lists {
  --space: 0;

  list-style: none;
  padding: 0;
  gap: 0;
}

.c-following-lists-loading {
  width: 100%;
  padding: var(--spacing-02);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-bg-alt);
  border: var(--border);
  border-top: 0;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

/* TODO: round border on the last child */
.c-following-lists *:last-child > button {
  /* border-radius: 0 0 var(--border-radius) var(--border-radius); */
}
