.r-lists {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: var(--spacing-02);
}

.r-lists-loading {
  padding: var(--spacing-01);
  width: max-content;
  background-color: var(--color-bg-alt);
  margin: auto;
}

.r-lists > header {
  align-items: flex-end;
  justify-content: space-between;
}

.r-lists .content-wrapper {
  flex-flow: row nowrap;
}

.r-lists .list-wrapper {
  gap: 0;
  background: var(--color-bg-alt);
  height: min-content;
  position: sticky;
  top: var(--spacing-02);
  overflow: hidden;
}

.r-lists-list-header {
  padding: var(--spacing-01) var(--spacing-02) 0 var(--spacing-02);
}

.r-lists .list-following-wrapper {
  gap: 0;
}

.r-lists .c-list-list {
  border-top: var(--border);
}

.list-following-wrapper .c-message {
  border: var(--border);
  border-top: 0;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.r-lists .list-wrapper .c-message {
  border-top: var(--border);
}

@media (max-width: 48em) {
  .r-lists {
    display: flex;
    flex-flow: column;
  }
}
